<template>
  <component :is="receiveAccountData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="receiveAccountData === undefined">
      <h4 class="alert-heading">獲取收款數據時出錯</h4>
      <div class="alert-body">
        找不到相關收款。請查看
        <b-link class="alert-link" :to="{ name: 'receiveAccount-receiveAccount-list' }">
          收款列表
        </b-link>
      </div>
    </b-alert>
    <receive-account-edit-tab-information
      v-if="receiveAccountData"
      :receive-account-data="receiveAccountData"
      class="p-sm-2"
      @refetch-data="refetchData"
    />

  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import receiveAccountStoreModule from "../receiveAccountStoreModule";
import receiveAccountEditTabInformation from "./receiveAccountEditTabInformation.vue";

export default {
  components: {
    receiveAccountEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {
    const receiveAccountData = ref({ 
      is_active:1,
      sort_order:0
    });
    const receiveAccount = {
      is_active:1,
      sort_order:0
    };

    const receive_account_APP_STORE_MODULE_NAME = "receive-account";

    if (!store.hasModule(receive_account_APP_STORE_MODULE_NAME))
      store.registerModule(receive_account_APP_STORE_MODULE_NAME, receiveAccountStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(receive_account_APP_STORE_MODULE_NAME))
        store.unregisterModule(receive_account_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "receive-account-create") {
        receiveAccountData.value = receiveAccount;
      } else {
        store
          .dispatch("receive-account/fetchReceiveAccount", { id: router.currentRoute.params.id })
          .then((response) => {
            receiveAccountData.value = response.data.receive_account;
            console.log(receiveAccountData.value)
          })
          .catch((error) => {
            console.log("error when fetching advance payment", error);
            if (error.response.status === 404) {
              receiveAccountData.value = undefined;
            }
          });
      }
    };

    // const bankOptions = ref([]);

    // const refetchOption = () => {
    //   store
    //     .dispatch("app/fetchOptionList", {
    //       banks: true,
    //     })
    //     .then((response) => {
    //       bankOptions.value = response.data.bank;
    //     });
    // };

    onMounted(() => {
      refetchData();
      // refetchOption();
    });

    return {
      receiveAccountData,
      refetchData
      // bankOptions,
      // refetchOption,
    };
  },
};
</script>
