<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-3" @submit.prevent="handleSubmit(onSubmit)" key="2">
          <div class="mt-2 mt-sm-3 position-relative">
            <b-row>
              <!-- bank -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="銀行名稱"
                  rules="required"
                >
                  <b-form-group
                    label="銀行名稱："
                    label-for="bank"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="bank"
                      v-model="newReceiveAccountData.bank"
                      placeholder="輸入銀行名稱"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="銀行帳號"
                  rules="required"
                >
                  <b-form-group
                    label="銀行帳號："
                    label-for="account_number"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="account_number"
                      v-model="newReceiveAccountData.account_number"
                      placeholder="輸入銀行帳號"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="備註"
                  rules="required"
                >
                  <b-form-group
                    label="備註："
                    label-for="remark"
                    label-cols-md="3"
                    class="required"
                  >
                    <b-form-input
                      id="remark"
                      v-model="newReceiveAccountData.remark"
                      placeholder="輸入備註"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="狀態"
                  rules="required"
                >
                  <b-form-group
                    label="狀態："
                    label-for="is_active"
                    label-cols-md="3"
                    :class="required"
                  >
                    <b-form-checkbox
                      :checked="newReceiveAccountData.is_active"
                      switch
                      inline
                      v-model="newReceiveAccountData.is_active"
                      value="1"
                      style="margin-top: 5px"
                    >
                      {{
                        newReceiveAccountData.is_active ? "Enable" : "Disable"
                      }}
                    </b-form-checkbox>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="排序"
                  rules="required"
                >
                  <b-form-group
                    label="排序："
                    label-for="sort_order"
                    label-cols-md="3"
                    :class="required"
                  >
                    <b-form-input
                      id="sort_order"
                      v-model="newReceiveAccountData.sort_order"
                      placeholder="輸入排序"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
// import uploadFile from "@/layouts/components/file/uploadFile.vue";

export default {
  data() {
    return {
      required,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // uploadFile,
  },
  methods: {
    hide() {
      this.$router.replace("/payment/receive-account/list");
    },
    // onFileUploaded(file) {
    //   this.newReceiveAccountData.image_files = file;
    // },
    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch(
              "receive-account/editReceiveAccount",
              this.newReceiveAccountData
            )
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              if (this.newReceiveAccountData.id == 0) {
                this.newReceiveAccountData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  props: {
    receiveAccountData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const newReceiveAccountData = ref({
      is_active: 1,
      sort_order: 0,
    });

    watch(
      () => props.receiveAccountData,
      (newVal) => {
        newReceiveAccountData.value = { ...newVal };
      }
    );
    const resetWorkData = () => {
      newReceiveAccountData.value = JSON.parse(
        JSON.stringify({
          is_active: 1,
          sort_order: 0,
        })
      );
    };

    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetWorkData);

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      newReceiveAccountData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
